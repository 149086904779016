<template>
  <div class="searchresultbox">

    <!-- <div class="proposedflight_box" v-if="isFcSupplied">הטיסה המוצעת</div> -->
    <div class="proposedflight_box" v-if="isFcSupplied">{{$t("sabre.search-result.box.recommended")}}</div>

    <div class="row align-items-center">
      <div class="col-lg-9 searchresultbox_right">
        <one-flight-item :data="data.legs[0]" :fcFlight="isFcSupplied"/>
      </div>
      <div class="col-lg-3">
        <div class="searchresultbox_left oneway d-flex align-items-center">
          <price-box :totalPrice="totalPrice" propsClass="w-50"/>
          <div class="w-50">
            <div class="d-block btnarea">
              <a href="#" class="btn btn-warning w-100" @click="() => orderFlight()">{{$t("sabre.buttons.order")}}</a>
            </div>
            <a class="Closedetail_btn collapsed" data-bs-toggle="collapse" :href="`#Closedetail1-${_uid}`" role="button">{{$t("sabre.buttons.more-detail")}}</a>
          </div>
        </div>
      </div>
    </div>

    <div class="collapse flightdetailscollapse" :id="`Closedetail1-${_uid}`">
      <collapse-flight-info :data="data" kind="one-way" :fcFlight="isFcSupplied"/>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  components: {
    CollapseFlightInfo: () => import('./searchResultBoxAtom/collapseFlightInfo'),
    OneFlightItem: () => import('./searchResultBoxAtom/oneFlightItem'),
    PriceBox: () => import('./searchResultBoxAtom/priceBox'),
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
      currentPage: 'GET_CURRENT_PAGE',
      itinGroups: 'GET_SABRE_FLIGHT_ITIN_GROUPS',
      isStaging: 'GET_STAGING_MODE',
    }),
    isFcSupplied() {
      const { itinGroups, data } = this;
      return itinGroups?.find((itin) => itin.id === data.itinGroupRef)?.fcSupplied;
    },
    totalPrice() {
      const { data } = this;
      return data.excerpt?.totalPrice || 0;
    },
    isChgFltPg() {
      return this.currentPage === 'sabre-change-flight';
    },
  },
  methods: {
    orderFlight() {
      const stagingParam = this.isStaging ? '/?staging=true' : '';
      this.$store.dispatch('SABRE_ORDER_FLIGHT', { flightId: this.data.id });
      this.$router.push({ path: `/sabre/fo-booking-passenger${stagingParam}` });
    },
  },
};
</script>
